var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { StyledTextBox } from "@animech/ui";
import { css } from "@emotion/react";
export var prefix = "application-speed-menu";
export var ApplicationSpeedMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tpadding: ", " ", ";\n\n\t\t\t.", "-pick {\n\t\t\t\tmargin: ", " ", ";\n\t\t\t\tbackground-image: linear-gradient(\n\t\t\t\t\t", ",\n\t\t\t\t\t", "\n\t\t\t\t);\n\t\t\t\tbackground-size: 100% 1px;\n\t\t\t\tbackground-repeat: no-repeat;\n\t\t\t\tbackground-position: center center;\n\t\t\t}\n\n\t\t\t.", "-pick-text {\n\t\t\t\twidth: fit-content;\n\t\t\t\tmargin: auto;\n\t\t\t\tbackground: ", ";\n\t\t\t\tpadding: 0 ", ";\n\t\t\t}\n\t\t"], ["\n\t\t\tpadding: ", " ", ";\n\n\t\t\t.", "-pick {\n\t\t\t\tmargin: ", " ", ";\n\t\t\t\tbackground-image: linear-gradient(\n\t\t\t\t\t", ",\n\t\t\t\t\t", "\n\t\t\t\t);\n\t\t\t\tbackground-size: 100% 1px;\n\t\t\t\tbackground-repeat: no-repeat;\n\t\t\t\tbackground-position: center center;\n\t\t\t}\n\n\t\t\t.", "-pick-text {\n\t\t\t\twidth: fit-content;\n\t\t\t\tmargin: auto;\n\t\t\t\tbackground: ", ";\n\t\t\t\tpadding: 0 ", ";\n\t\t\t}\n\t\t"])), theme.dimensions.margin.base, theme.dimensions.margin.base, prefix, theme.dimensions.margin.base, theme.dimensions.margin.small, theme.colors.palette.grey[700], theme.colors.palette.grey[700], prefix, theme.colors.palette.grey[800], theme.dimensions.margin.base);
});
export var ColoredTextBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmargin-bottom: 10px;\n\n\t", " {\n\t\tborder: none;\n\t\tborder-left: 5px solid ", ";\n\t\tborder-right: 5px solid transparent;\n\n\t\tsvg {\n\t\t\tstroke: none;\n\t\t\toverflow: hidden !important;\n\t\t}\n\t}\n"], ["\n\tmargin-bottom: 10px;\n\n\t", " {\n\t\tborder: none;\n\t\tborder-left: 5px solid ", ";\n\t\tborder-right: 5px solid transparent;\n\n\t\tsvg {\n\t\t\tstroke: none;\n\t\t\toverflow: hidden !important;\n\t\t}\n\t}\n"])), StyledTextBox.Root, function (props) { return props.color; });
var templateObject_1, templateObject_2, templateObject_3;
