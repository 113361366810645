var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { TextBox } from "@animech/ui";
import * as Styled from "./application-speed-menu.style";
import { round, Unit } from "utility/number";
import { useAppSelector, selectors } from "store";
import { ParameterInput } from "ui/components/elements/parameter-input";
import { PerformanceChartGroup } from "store/types";
import { LabelWithDescription } from "ui/components/elements/label-with-description";
import { parameterNames } from "api/tacton/constants";
export var ApplicationSpeedMenu = function () {
    var _a;
    var t = useTranslation().t;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var getGroupComponents = function (group) {
        return (dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.applicationSpeed) &&
            Object.values(dashboardData.applicationSpeed)
                .filter(function (chartField) { return chartField.group === group; })
                .map(function (chartField) {
                var _a, _b;
                var field = chartField;
                return (_jsxs(Styled.ColoredTextBox, { color: field.color, children: [field.parameterId &&
                            (systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[field.parameterId]) && (_jsx(ParameterInput, { parameter: __assign(__assign({}, systemDataDefineParameters.parameters[field.parameterId]), { label: t(field.translationKey), unit: Unit.S }) })), !field.parameterId && (_jsxs(_Fragment, { children: [_jsx(LabelWithDescription, { label: t(field.translationKey), description: field.descriptionKey
                                        ? { long: t(field.descriptionKey) }
                                        : undefined }), _jsx(TextBox, { value: (_b = (_a = round(field.value, 3)) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "-", unit: Unit.S, disabled: true })] }))] }, field.id));
            });
    };
    var targetPartsPerMinuteParameter = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[parameterNames.targetPartsPerMinute];
    return (_jsxs(Styled.ApplicationSpeedMenu, { children: [(dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.targetPartsPerMinute.value) &&
                targetPartsPerMinuteParameter && (_jsx(ParameterInput, { parameter: __assign(__assign({}, targetPartsPerMinuteParameter), { label: targetPartsPerMinuteParameter.label }) })), _jsx("div", { className: "".concat(Styled.prefix, "-pick"), children: _jsx("div", { className: "".concat(Styled.prefix, "-pick-text"), children: t("dashboard.vacuum_levels") }) }), (dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.desiredVacuumLevels.partSecured.parameterId) &&
                (systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[dashboardData.desiredVacuumLevels.partSecured.parameterId]) && (_jsx(Styled.ColoredTextBox, { color: dashboardData.desiredVacuumLevels.partSecured.color, children: _jsx(ParameterInput, { parameter: __assign(__assign({}, systemDataDefineParameters.parameters[dashboardData.desiredVacuumLevels.partSecured.parameterId]), { label: t(dashboardData.desiredVacuumLevels.partSecured.translationKey) }) }) }, dashboardData.desiredVacuumLevels.partSecured.id)), ((_a = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.desiredVacuumLevels.energySaving) === null || _a === void 0 ? void 0 : _a.parameterId) &&
                (systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[dashboardData.desiredVacuumLevels.energySaving.parameterId]) && (_jsx(Styled.ColoredTextBox, { color: dashboardData.desiredVacuumLevels.energySaving.color, children: _jsx(ParameterInput, { parameter: __assign(__assign({}, systemDataDefineParameters.parameters[dashboardData.desiredVacuumLevels.energySaving.parameterId]), { label: t(dashboardData.desiredVacuumLevels.energySaving.translationKey) }) }) }, dashboardData.desiredVacuumLevels.energySaving.id)), _jsx("div", { className: "".concat(Styled.prefix, "-pick"), children: _jsx("div", { className: "".concat(Styled.prefix, "-pick-text"), children: t("dashboard.before_pick") }) }), getGroupComponents(PerformanceChartGroup.BEFORE_PICK), _jsx("div", { className: "".concat(Styled.prefix, "-pick"), children: _jsx("div", { className: "".concat(Styled.prefix, "-pick-text"), children: t("dashboard.pick") }) }), getGroupComponents(PerformanceChartGroup.BEFORE_PLACE), _jsx("div", { className: "".concat(Styled.prefix, "-pick"), children: _jsx("div", { className: "".concat(Styled.prefix, "-pick-text"), children: t("dashboard.place") }) }), getGroupComponents(PerformanceChartGroup.AFTER_PLACE)] }));
};
