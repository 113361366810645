var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledLabel, StyledTextBox, StyledTooltip } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CustomUITooltip } from "ui/components/elements/custom-ui-tooltip";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { StepperInput } from "ui/components/elements/input/stepper-input";
export var Length = styled(ConvertedNumberInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var LengthWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var Diameter = styled(StepperInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var DiameterWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var LengthAndDiameter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-wrap: nowrap;\n\theight: 70px;\n\talign-items: center;\n\tjustify-content: space-between;\n\tgap: 10px;\n\tmargin-bottom: 7px;\n\n\t", ", ", " {\n\t\talign-items: center;\n\t}\n\n\t", " {\n\t\t", " {\n\t\t\theight: 30px;\n\t\t}\n\t}\n\n\t", " {\n\t\t", " {\n\t\t\tmargin-bottom: 4px !important;\n\t\t}\n\t}\n"], ["\n\tdisplay: flex;\n\tflex-wrap: nowrap;\n\theight: 70px;\n\talign-items: center;\n\tjustify-content: space-between;\n\tgap: 10px;\n\tmargin-bottom: 7px;\n\n\t", ", ", " {\n\t\talign-items: center;\n\t}\n\n\t", " {\n\t\t", " {\n\t\t\theight: 30px;\n\t\t}\n\t}\n\n\t", " {\n\t\t", " {\n\t\t\tmargin-bottom: 4px !important;\n\t\t}\n\t}\n"])), Length, Diameter, LengthWrapper, StyledTextBox.Root, DiameterWrapper, StyledLabel.Root);
export var DoubleTubeWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var unitSystem = _a.unitSystem;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\tmargin-left: ", ";\n\t\t\tmargin-top: 10px;\n\t\t\tmargin-bottom: 10px;\n\n\t\t\tlabel {\n\t\t\t\tmargin-bottom: 4px !important;\n\t\t\t}\n\t\t"], ["\n\t\t\tmargin-left: ", ";\n\t\t\tmargin-top: 10px;\n\t\t\tmargin-bottom: 10px;\n\n\t\t\tlabel {\n\t\t\t\tmargin-bottom: 4px !important;\n\t\t\t}\n\t\t"])), unitSystem === "metric" ? "56%" : "41%");
});
export var InputTooltip = styled(CustomUITooltip)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\tbackground-color: ", " !important;\n\t\t\t\tpadding: 6px 10px;\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\tbackground-color: ", " !important;\n\t\t\t\tpadding: 6px 10px;\n\t\t\t}\n\t\t"])), StyledTooltip.Content, theme.colors.palette.grey[800]);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
